import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CiCalendarDate } from "react-icons/ci";
import { IoCheckmarkDoneCircleOutline, IoFastFood } from "react-icons/io5";
import { SessionContext } from "../../components/SessionContext";
import supabase from "../../supabase";
import "./order.css";
import { InduvidualItem } from "../statistics/Statistics";
import { formatISO, startOfDay, endOfDay, set } from "date-fns";

const OrderCard = ({ amount, items, user, serveStatus }) => {
  return (
    <div
      className="just-card productsans-regular order-card"
      style={{
        borderLeft: "5px solid ",
        borderLeftColor: serveStatus == "served" ? "#2f8d32" : "#F42C39",
      }}
    >
      <div className="order-left">
        <span className="user-name">
          {user && user.raw_user_meta_data.name.split(" ")[0]}
        </span>
        <span className="poppins-regular order-email">
          {user && user.raw_user_meta_data.email}
        </span>
      </div>
      <div className="order-right">
        {Object.values(items).map((item) => (
          <>
            <span>
              {item.count}x <space />
              {item.name}
            </span>
          </>
        ))}
        <span
          style={{
            fontSize: "1.2rem",
          }}
        >
          Total ₹{amount}
        </span>
      </div>
    </div>
  );
};

const OrderHistory = () => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());

  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [filterOption, setFilterOption] = useState("to-serve");
  useEffect(() => {
    if (filterOption === "served") {
      setFilteredOrders(orders.filter((order) => order.served === true));
    } else if (filterOption === "to-serve") {
      setFilteredOrders(orders.filter((order) => order.served === false));
    }
  }, [orders, filterOption]);
  useEffect(() => {
    fetchAllTodayOrders();
  }, []);

  useEffect(() => {
    const orderTable = supabase
      .channel("*")
      .on(
        "postgres_changes",
        {
          schema: "public",
          table: "orders",
          event: "*",
        },
        async (payload) => {
          console.log(payload.new);
          const newOrder = payload.new;
          const todayStart = new Date();
          todayStart.setHours(0, 0, 0, 0);
          const todayEnd = new Date();
          todayEnd.setHours(23, 59, 59, 999);
          const orderDate = new Date(newOrder.created_at);

          if (
            orderDate >= todayStart &&
            orderDate <= todayEnd &&
            newOrder.status === "paid"
          ) {
            const { data: user, error } = await supabase
              .from("users")
              .select("raw_user_meta_data")
              .eq("id", newOrder.user_id)
              .single();

            if (error) {
              console.log("error", error);
            } else {
              newOrder.user_id = user;
              console.log(user.raw_user_meta_data.name);
              setOrders((prevOrders) => [newOrder, ...prevOrders]);
            }
          }
        }
      )
      .subscribe();

    return () => {
      supabase.removeChannel(orderTable);
    };
  }, [supabase]);

  const fetchOrdersByDate = async (date) => {
    const todayStart = new Date(date);
    todayStart.setHours(0, 0, 0, 0);
    const todayEnd = new Date(date);
    todayEnd.setHours(23, 59, 59, 999);
    let { data: orders, error } = await supabase
      .from("orders")
      .select(
        `
    *,
    user_id:users (
      raw_user_meta_data
    )
  `
      )
      .gte("created_at", todayStart.toISOString())
      .lte("created_at", todayEnd.toISOString())
      .eq("status", "paid")
      .order("created_at", { ascending: false });
    if (error) console.log("error", error);
    else {
      console.log(orders);
      setOrders(orders);
    }
  };

  const fetchAllTodayOrders = async () => {
    const todayStart = new Date();
    todayStart.setHours(0, 0, 0, 0);
    const todayEnd = new Date();
    todayEnd.setHours(23, 59, 59, 999);
    let { data: orders, error } = await supabase
      .from("orders")
      .select(
        `
    *,
    user_id:users (
      raw_user_meta_data
    )
  `
      )
      .gte("created_at", todayStart.toISOString())
      .lte("created_at", todayEnd.toISOString())
      .eq("status", "paid")
      .order("created_at", { ascending: false });
    if (error) console.log("error", error);
    else {
      console.log(orders);
      setOrders(orders);
    }
  };

  const { session } = useContext(SessionContext);
  if (!session) {
    window.location.href = "/";
  }

  //-----------ITems to serve ------------------

  const [itemsToServe, setItemsToServe] = useState({});
  const fetchItemsToServe = async () => {
    const now = new Date();
    const startOfToday = startOfDay(now);
    const endOfToday = endOfDay(now);

    const { data: orders, error } = await supabase
      .from("orders")
      .select("items")
      .filter("created_at", "gte", formatISO(startOfToday))
      .filter("created_at", "lt", formatISO(endOfToday))
      .filter("served", "eq", false)
      .filter("status", "eq", "paid");

    if (error) {
      console.error(error);
      return;
    }

    const itemCounts = orders.reduce((counts, order) => {
      const items = order.items;
      Object.keys(items).forEach((itemName) => {
        if (!counts[itemName]) {
          counts[itemName] = {
            itemName: items[itemName].name,
            orders: 0,
            image: items[itemName].image,
          };
        }
        counts[itemName].orders += items[itemName].count;
      });
      return counts;
    }, {});

    setItemsToServe(itemCounts);
  };

  useEffect(() => {
    fetchItemsToServe();
    setInterval(() => {
      fetchItemsToServe();
    }, 10000);
  }, []);

  return (
    <>
      <h1
        style={{
          fontSize: "2em",
          color: "white",
          margin: "0 0 0.5em 0",
          fontFamily: "Grifter",
          textAlign: "center",
          marginTop: "1em",
        }}
      >
        Items To Serve
      </h1>
      <div className="stat-ind-item">
        {Object.keys(itemsToServe).map((itemName) => {
          const item = itemsToServe[itemName];
          return (
            <InduvidualItem
              key={itemName}
              name={item.itemName}
              orders={item.orders}
              image={item.image}
            />
          );
        })}
      </div>
      <div className="view-users-page">
        <div className="filter-container productsans-regular">
          <button
            onClick={() => setFilterOption("to-serve")}
            className={`filter-button ${
              filterOption === "to-serve" ? "filter-button-active" : ""
            }`}
          >
            <IoFastFood
              style={{
                display: "inline-block",
                marginRight: "5px",
                marginTop: "-2px",
              }}
            />
            To Serve
          </button>
          <button
            onClick={() => setFilterOption("served")}
            className={`filter-button ${
              filterOption === "served" ? "filter-button-active" : ""
            }`}
          >
            <IoCheckmarkDoneCircleOutline
              style={{
                display: "inline-block",
                marginRight: "5px",
                marginTop: "-2px",
              }}
            />
            Served
          </button>
          <button
            onClick={(event) => {
              event.preventDefault();
              setShowDatePicker(true);
            }}
            className={`filter-button ${
              filterOption === "custom" ? "filter-button-active" : ""
            }`}
          >
            <CiCalendarDate
              style={{
                display: "inline-block",
                marginRight: "5px",
                marginTop: "-2px",
              }}
            />
            By Date
          </button>
          {showDatePicker && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-10">
              <div className="bg-white p-4 rounded shadow">
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => {
                    setSelectedDate(date);
                    fetchOrdersByDate(date);
                    setShowDatePicker(false);
                  }}
                  inline
                />
              </div>
            </div>
          )}
        </div>

        {selectedDate && (
          <div
            className="productsans-regular text-center"
            style={{
              fontSize: "2rem",
              marginBottom: "10px",
              color: "#fff",
            }}
          >
            {selectedDate.toDateString()}
          </div>
        )}
        {filteredOrders.map((order) => (
          <OrderCard
            key={order.id}
            user={order.user_id}
            amount={order.amount}
            items={order.items}
            serveStatus={filterOption}
          />
        ))}
      </div>
    </>
  );
};

export default OrderHistory;
