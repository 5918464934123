import React, { useEffect, useState } from "react";
import supabase from "../../supabase";
import { endOfToday, format } from "date-fns";
import {
  startOfMonth,
  endOfMonth,
  formatISO,
  startOfDay,
  endOfDay,
} from "date-fns";

export const InduvidualItem = ({ name, orders, image }) => {
  return (
    <div className="dish-card">
      <div
        className="dish-left"
        style={{
          fontSize: "1.2em",
        }}
      >
        <span>
          <span
            className="productsans-regular dish-name"
            style={{ display: "inline-block", verticalAlign: "middle" }}
          >
            {name}
          </span>
        </span>
        <br />
        <span className="dish-price">{orders}</span>
      </div>
      <img className="dish-image" src={image} />
    </div>
  );
};

const Statistics = () => {
  const [todayRevenue, setRevenue] = useState(0);
  const [todayOrders, settodayOrders] = useState(0);
  const [monthRevenue, setMonthRevenue] = useState(0);
  const [mostOrderedItem, setMostOrderedItem] = useState("");
  const [mostOrderedItemCount, setMostOrderedItemCount] = useState(0);
  const [itemsOrderedToday, setItemsOrderedToday] = useState({});

  // Functions for manual fetching-----------------------

  const fetchTodayRevenue = async () => {
    const today = format(new Date(), "yyyy-MM-dd");
    const startOfToday = startOfDay(new Date());
    const endOfToday = endOfDay(new Date());
    const { data: orders, error } = await supabase
      .from("orders")
      .select("amount")
      .filter("created_at", "gte", formatISO(startOfToday))
      .filter("created_at", "lt", formatISO(endOfToday))
      .filter("status", "eq", "paid");

    if (error) {
      console.error(error);
      return;
    }
    console.log(orders);
    const todayRevenue = orders.reduce((sum, order) => sum + order.amount, 0);
    const formattedRevenue = parseFloat(todayRevenue.toFixed(2));
    setRevenue(formattedRevenue);
    settodayOrders(orders.length);
  };

  const fetchMonthRevenue = async () => {
    const now = new Date();
    const startOfThisMonth = startOfMonth(now);
    const endOfThisMonth = endOfMonth(now);

    const { data: orders, error } = await supabase
      .from("orders")
      .select("amount")
      .filter("created_at", "gte", formatISO(startOfThisMonth))
      .filter("created_at", "lt", formatISO(endOfThisMonth))
      .filter("status", "eq", "paid");

    if (error) {
      console.error(error);
      return;
    }
    const monthRevenue = orders.reduce((sum, order) => sum + order.amount, 0);
    const formattedRevenue = parseFloat(monthRevenue.toFixed(2));
    setMonthRevenue(formattedRevenue);
  };

  const fetchMostOrderedItem = async () => {
    const now = new Date();
    const startOfThisMonth = startOfMonth(now);
    const endOfThisMonth = endOfMonth(now);

    const { data: orders, error } = await supabase
      .from("orders")
      .select("items")
      .filter("created_at", "gte", formatISO(startOfThisMonth))
      .filter("created_at", "lt", formatISO(endOfThisMonth))
      .filter("status", "eq", "paid");

    if (error) {
      console.error(error);
      return;
    }
    const itemCounts = orders.reduce((counts, order) => {
      const items = order.items;
      Object.keys(items).forEach((itemName) => {
        if (!counts[itemName]) {
          counts[itemName] = 0;
        }
        counts[itemName] += items[itemName].count;
      });
      return counts;
    }, {});

    const mostOrderedItem = Object.keys(itemCounts).reduce((a, b) =>
      itemCounts[a] > itemCounts[b] ? a : b
    );

    setMostOrderedItem(mostOrderedItem);
    setMostOrderedItemCount(itemCounts[mostOrderedItem]);
  };

  const fetchItemsOrderedToday = async () => {
    const now = new Date();
    const startOfToday = startOfDay(now);
    const endOfToday = endOfDay(now);

    const { data: orders, error } = await supabase
      .from("orders")
      .select("items")
      .filter("created_at", "gte", formatISO(startOfToday))
      .filter("created_at", "lt", formatISO(endOfToday))
      .filter("status", "eq", "paid");

    if (error) {
      console.error(error);
      return;
    }

    const itemCounts = orders.reduce((counts, order) => {
      const items = order.items;
      Object.keys(items).forEach((itemName) => {
        if (!counts[itemName]) {
          counts[itemName] = {
            itemName: items[itemName].name,
            orders: 0,
            image: items[itemName].image,
          };
        }
        counts[itemName].orders += items[itemName].count;
      });
      return counts;
    }, {});

    setItemsOrderedToday(itemCounts);
    console.log(itemCounts);
  };

  useEffect(() => {
    fetchTodayRevenue();
    fetchMonthRevenue();
    fetchMostOrderedItem();
    fetchItemsOrderedToday();
  }, []);
  //------------------------

  // fetch with 30s interval-------------------------
  useEffect(() => {
    const interval = setInterval(() => {
      fetchTodayRevenue();
      fetchMonthRevenue();
      fetchMostOrderedItem();
      fetchItemsOrderedToday();
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  //Realtime----------------------------------
  // useEffect(() => {
  //   const orderChannel = supabase
  //     .channel("*")
  //     .on(
  //       "postgres_changes",
  //       {
  //         schema: "public",
  //         table: "orders",
  //         event: "UPDATE",
  //       },
  //       () => {
  //         fetchTodayRevenue();
  //         fetchMonthRevenue();
  //         fetchMostOrderedItem();
  //         fetchItemsOrderedToday();
  //       }
  //     )
  //     .subscribe();
  //   return () => {
  //     supabase.removeChannel(orderChannel);
  //   };
  // }, [supabase]);
  //-------------------------------------------
  return (
    <div className="statistics productsans-regular">
      <h1
        style={{
          fontFamily: "Grifter",
          fontSize: "4em",
          color: "white",
          textAlign: "center",
        }}
      >
        STATISTICS
      </h1>
      <h2
        style={{
          fontSize: "2em",
          color: "white",
        }}
      >
        Product Data
      </h2>
      <div className="stat-ind-item">
        {Object.keys(itemsOrderedToday).map((itemName) => {
          const item = itemsOrderedToday[itemName];
          return (
            <InduvidualItem
              key={itemName}
              name={item.itemName}
              orders={item.orders}
              image={item.image}
            />
          );
        })}
      </div>
      <br />
      <h2
        style={{
          fontSize: "2em",
          color: "white",
          marginBottom: "-0.5em",
        }}
      >
        Sale Data
      </h2>
      <div className="portal-card statistics-box">
        Revenue Today: ₹{todayRevenue}
        <br />
        Orders Today: {todayOrders}
      </div>

      <div className="portal-card statistics-box">
        Revenue This Month: ₹{monthRevenue}
      </div>
      <div className="portal-card statistics-box">
        Most Ordered this Month: {mostOrderedItem}, {mostOrderedItemCount}
      </div>
    </div>
  );
};

export default Statistics;
