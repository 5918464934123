import { useEffect, useRef } from 'react';
import QrScanner from 'qr-scanner';

const NimiqScannerComponent = ({onResult,onError,className}) => {
  const videoRef = useRef(null);

  useEffect(() => {
    let qrscanner;
    if (videoRef.current) {
        qrscanner = new QrScanner(videoRef.current,
        result => {
          console.log(result);
          onResult(result)},
        {
          highlightScanRegion: true,
          highlightCodeOutline: true,
        }
        );
        qrscanner.setInversionMode("both"); 
        qrscanner.start();

    }

    // Cleanup function to stop scanning when component unmounts
    return () => {
      if (qrscanner) {
        qrscanner.stop();
      }
    };
  }, []); // Empty dependency array means this effect runs once on mount and clean up on unmount

  return (
   <div className='w-full flex flex-col justify-center items-center'>
     <video id="qr-video" className={className} ref={videoRef} />

   </div>
    
  );
};

export default NimiqScannerComponent;