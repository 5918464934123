import React, { useContext, useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { GrRadialSelected } from "react-icons/gr";
import {
  IoIosAddCircle,
  IoIosCloseCircle,
  IoIosRemoveCircle,
  IoIosCheckmarkCircle,
} from "react-icons/io";
import ProfilePhoto from "../../components/ProfilePhoto";
import { SessionContext } from "../../components/SessionContext";
import EggLoading from "../../static/eggloading";
import incrementSound from "../../static/increment.wav";
import PopSound from "../../static/pop.mp3";
import supabase from "../../supabase";
import "./editmenu.css";
import { Add } from "@mui/icons-material";

const incrementSoundEffect = new Audio(incrementSound);
const PopSoundEffect = new Audio(PopSound);

async function fetchDishes(setMenu, setSearchMenu) {
  const { data: dishes, error } = await supabase.from("menu").select("*");
  if (error) {
    console.error(error);
  } else {
    setSearchMenu(dishes);
  }
}
async function updateDish(dish) {
  const { error } = await supabase.from("menu").upsert([dish]);
  if (error) {
    console.error(error);
  }
}

const AddModal = ({ onClose }) => {
  const [name, setName] = useState("");
  const [cost, setCost] = useState(0);
  const [stock, setStock] = useState(0);
  const [category, setCategory] = useState("food");
  const [foodType, setFoodType] = useState(true);

  const submitFood = async () => {
    console.log("Submitting Food");
    const { error } = await supabase.from("menu").insert([
      {
        name: name,
        cost: cost,
        stock: stock,
        category: category,
        food_type: foodType,
        order_limit: 5,
        stock_limit: 10,
      },
    ]);
    if (error) {
      console.error(error);
    } else {
      onClose();
      console.log("No erorr");
      PopSoundEffect.play();
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <IoIosCheckmarkCircle
          onClick={submitFood}
          className="submit-icon"
          color="green"
          size={35}
        />
        <IoIosCloseCircle
          onClick={() => {
            onClose();
          }}
          className="close-icon"
          color="red"
          size={35}
        />
        <h1 className="productsans-regular">Adding New Item</h1>

        <div className="modal-data">
          <div className="grifter-regular modal-keys">
            <p>Item Name: </p>
            <p>Cost: </p>
            <p>Stock: </p>
            <p>Category</p>
            <p>Veg</p>
          </div>
          <div className="poppins-regular modal-values">
            <input
              type="text"
              className="modal-dish-name"
              defaultValue={name}
              onChange={(e) => setName(e.target.value)}
            />
            <div className="modal-cost">
              <IoIosRemoveCircle
                onClick={() => {
                  incrementSoundEffect.play();

                  if (cost > 0) {
                    setCost(cost - 1);
                  } else {
                    setCost(0);
                  }
                }}
                className="cost-icon"
                size={30}
                color="#F42C39"
              />

              <input
                type="number"
                className="modal-dish-name"
                defaultValue={cost}
                value={cost}
                onChange={(e) => {
                  setCost(parseInt(e.target.value));
                }}
                style={{
                  marginTop: "-2px",
                }}
              />
              <IoIosAddCircle
                size={30}
                onClick={() => {
                  incrementSoundEffect.play();
                  setCost(cost + 1);
                }}
                className="cost-icon"
                color="#24603f"
              />
            </div>
            <div className="modal-stock">
              <IoIosRemoveCircle
                onClick={() => {
                  incrementSoundEffect.play();
                  if (stock > 0) {
                    setStock(stock - 1);
                  } else {
                    setStock(0);
                  }
                }}
                className="stock-icon"
                size={30}
                color="#F42C39"
              />

              <input
                type="number"
                className="modal-dish-name"
                defaultValue={stock}
                value={stock}
                onChange={(e) => {
                  setStock(parseInt(e.target.value));
                }}
                style={{
                  marginTop: "-2px",
                }}
              />
     

              <IoIosAddCircle
                size={30}
                onClick={() => {
                  incrementSoundEffect.play();
                  setStock(stock + 1);
                }}
                className="stock-icon"
                color="#24603f"
              />
            </div>
            <div className="modal-cat">
              <select
                className="modal-dish-name"
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
              >
                <option value="food">Food</option>
                <option value="curry">Curry</option>
                <option value="drinks">Drinks</option>
              </select>
            </div>

            <div className="modal-veg">
              <input
                type="checkbox"
                className="modal-dish-name modal-input"
                onChange={(e) => {
                  setFoodType(e.target.checked);
                  console.log(e.target.checked);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const EditModal = ({ dish, onClose }) => {
  const [name, setName] = useState(dish.name);
  const [cost, setCost] = useState(dish.cost);
  const [stock, setStock] = useState(dish.stock);

  const submitData = () => {
    updateDish({ id: dish.id, name, cost, stock });
    onClose();
    PopSoundEffect.play();
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <IoIosCloseCircle
          onClick ={submitData}
          className="close-icon"
          color="red"
          size={35}
        />
        <h1 className="productsans-regular">Editing {name}</h1>

        <div className="modal-data">
          <div className="grifter-regular modal-keys">
            <p>Item Name: </p>
            <p>Cost: </p>
            <p>Stock: </p>
          </div>
          <div className="poppins-regular modal-values">
            <input
              type="text"
              className="modal-dish-name"
              defaultValue={name}
              onChange={(e) => setName(e.target.value)}
            />
            <div className="modal-cost">
              <IoIosRemoveCircle
                onClick={() => {
                  incrementSoundEffect.play();

                  if (cost > 0) {
                    setCost(cost - 1);
                  } else {
                    setCost(0);
                  }
                }}
                className="cost-icon"
                size={30}
                color="#F42C39"
              />
              {/* <p>{cost}</p> */}
              <input
                type="number"
                className="modal-dish-name"
                defaultValue={cost}
                value={cost}
                onChange={(e) => {
                  setCost(parseInt(e.target.value));
                }}
                style={{
                  marginTop: "-2px",
                }}
              />
              <IoIosAddCircle
                size={30}
                onClick={() => {
                  incrementSoundEffect.play();
                  setCost(cost + 1);
                }}
                className="cost-icon"
                color="#24603f"
              />
            </div>
            <div className="modal-stock">
              <IoIosRemoveCircle
                onClick={() => {
                  incrementSoundEffect.play();
                  if (stock > 0) {
                    setStock(stock - 1);
                  } else {
                    setStock(0);
                  }
                }}
                className="stock-icon"
                size={30}
                color="#F42C39"
              />
              {/* <p>{stock}</p> */}
              <input
                type="number"
                className="modal-dish-name"
                defaultValue={stock}
                value={stock}
                onChange={(e) => {
                  setStock(parseInt(e.target.value));
                }}
                style={{
                  marginTop: "-2px",
                }}
              />
              <IoIosAddCircle
                size={30}
                onClick={() => {
                  incrementSoundEffect.play();
                  setStock(stock + 1);
                }}
                className="stock-icon"
                color="#24603f"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Category = ({ category, selectedCategory, setSelectedCategory }) => {
  return (
    <span
      onClick={() => setSelectedCategory(category)}
      className={`${
        selectedCategory === category ? "category-selected" : ""
      } productsans-regular food-category`}
    >
      {category}
    </span>
  );
};

const SearchDish = () => {
  const [selectedCategory, setSelectedCategory] = React.useState("All");
  const [menu, setMenu] = useState([]);
  const [search, setSearch] = useState("");
  const [searchMenu, setSearchMenu] = useState(menu);
  const [loading, setLoading] = useState(true);
  const [showEditModal, setshowEditModal] = useState(false);
  const [editDish, setEditDish] = useState({});

  useEffect(() => {
    const menuChannel = supabase
      .channel("*")
      .on(
        "postgres_changes",
        {
          schema: "public",
          table: "menu",
          event: "*",
        },
        (payload) => {
          setSearchMenu((prevMenu) => {
            const updatedMenu = prevMenu.map((item) => {
              if (item.id === payload.new.id) {
                return payload.new;
              }
              return item;
            });
            return updatedMenu;
          });
        }
      )
      .subscribe();
    return () => {
      supabase.removeChannel(menuChannel);
    };
  }, [supabase]);

  useEffect(() => {
    fetchDishes(setMenu, setSearchMenu);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, [selectedCategory]);

  function setSearchValue(search) {
    setSearch(search);
    if (search.lenght < 1) {
      setSearchMenu(menu);
      return;
    }
    const filteredMenu = menu.filter((dish) =>
      dish.name.toLowerCase().includes(search.toLowerCase())
    );
    setSearchMenu(filteredMenu);
  }

  const resultScreen = (
    <div>
      {showEditModal && (
        <>
          <div className="modal-backdrop" />

          <EditModal
            dish={editDish}
            onClose={() => {
              setshowEditModal(false);
            }}
          />
        </>
      )}

      <div className="input-icon">
        <input
          type="text"
          placeholder="Search for Dishes"
          className="search-dish productsans-regular"
          value={search}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <CiSearch className="search-icon" color="white" size={30} />
      </div>
      <div className="menu-categories">
        {["All", "Food", "Curry", "Drinks"].map((category) => (
          <Category
            key={category}
            category={category}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
        ))}
      </div>
      <div className="dish-list min-h-fit h-[50vh]">
        {searchMenu.map((dish) => {
          if (
            selectedCategory === "All" ||
            selectedCategory.toLowerCase() === dish.category
          ) {
            return (
              <Dish
                id={dish.id}
                name={dish.name}
                cost={dish.cost}
                image={dish.image}
                type={dish.food_type}
                onClick={() => {
                  setEditDish(dish);
                  setshowEditModal(true);
                }}
              />
            );
          }
          return null;
        })}
        <span
          style={{ color: "#AEADAD", fontWeight: 100 }}
          className="poppins-regular text-center"
        >
          Thats all for now
        </span>
      </div>
    </div>
  );

  return (
    <div className="search-dish-screen">
      {loading ? <EggLoading /> : resultScreen}
    </div>
  );
};
const Dish = ({ id, name, cost, image, type, onClick }) => {
  return (
    <div className="dish-card">
      <div className="dish-left">
        <span>
          <GrRadialSelected
            color={type ? "#27DB97" : "#F42C39"}
            style={{
              display: "inline-block",
              verticalAlign: "middle",
              marginRight: "10px",
            }}
          />
          <span
            className="productsans-regular dish-name"
            style={{ display: "inline-block", verticalAlign: "middle" }}
          >
            {name}
          </span>
        </span>
        <span className="dish-price">₹{cost}</span>
      </div>
      <img className="dish-image" src={image} alt={name} />

      <div
        onClick={onClick}
        // onClick={() => {
        //   setshowEditModal(true);
        // }}
        className="absolute  right-12 w-1/4 h-1/4 bg-slate-50 -bottom-3 flex justify-center items-center text-center rounded-md text-black font-bold transition duration-500 ease-in-out cursor-pointer"
      >
        Edit
      </div>
    </div>
  );
};

function Menu() {
  const { session } = useContext(SessionContext);
  const avatarInfo = session?.user;
  const [showAddModal, setshowAddModal] = useState(false);
  const killswitchHandler = async () => {
    const { data, error } = await supabase.from("menu").select("id");

    if (error) {
      console.error(error);
      return;
    }

    for (const row of data) {
      const { error: updateError } = await supabase
        .from("menu")
        .update({ stock: 0 })
        .eq("id", row.id);

      if (updateError) {
        console.error(updateError);
      }
    }

    console.log("No error, updated all to zero");
  };
  return (
    <div className="menu-screen">
      <div className="killswitch" onClick={killswitchHandler}>
        Set All Stock to 0
      </div>
      <div className="menu-screen-title m-0">
        <span style={{ color: "#ffff" }} className="grifter-regular">
          Edit Menu
        </span>
        
      </div>
      <ProfilePhoto avatarInfo={avatarInfo} />

      {showAddModal && (
        <>
          <div className="modal-backdrop" />
          <AddModal
            onClose={() => {
              setshowAddModal(false);
            }}
          />
        </>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          onClick={() => {
            setshowAddModal(true);
          }}
          className="add-dish"
        >
          Add New Item
        </div>
      </div>

      <SearchDish />
      </div>
  );
}

export default Menu;
