import React, { useContext, useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { SessionContext } from "../../components/SessionContext";
import supabase from "../../supabase";
import "./viewusers.css";

const UserCard = ({ user, email, profile_pic }) => {
  return (
    <div className="just-card productsans-regular">
      <div>
        <img
          src={
            profile_pic ||
            `https://api.dicebear.com/8.x/fun-emoji/png?seed=${email}`
          }
          alt=""
          referrerpolicy="no-referrer"
        />
      </div>
      <div className="order-right">
        {user ? null : (
          <span className="itsamerchant poppins-regular ">Merchant</span>
        )}
        <span
          style={{
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
        >
          {user ? user : email.split("@")[0]}
        </span>
        <span className="poppins-regular" style={{ fontSize: "0.9em" }}>
          {email}
        </span>
      </div>
    </div>
  );
};

const fetchUsers = async (setUsers) => {
  const { data, error } = await supabase.from("users").select("*");
  if (error) {
    console.error(error);
  } else {
    setUsers(data);
  }
};

const SearchUser = ({ searchValue, setSearchValue }) => {
  return (
    <div className="input-icon user-search">
      <input
        type="text"
        placeholder="Search for Users"
        className="search-dish productsans-regular"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <CiSearch className="search-icon" color="white" size={30} />
    </div>
  );
};

const ViewUsers = () => {
  const [users, setUsers] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    fetchUsers(setUsers);
  }, []);

  const { session } = useContext(SessionContext);

  if (!session) {
    window.location.href = "/";
  }
  const filteredUsers = users.filter((user) => {
    if (user.raw_user_meta_data && user.raw_user_meta_data.name) {
      return user.raw_user_meta_data.name
        .toLowerCase()
        .includes(searchValue.toLowerCase());
    } else {
      return user.email.toLowerCase().includes(searchValue.toLowerCase());
    }
  });
  return (
    <div className="view-users-page">
      <SearchUser setSearchValue={setSearchValue} searchValue={searchValue} />
      {filteredUsers.map((user) => (
        <UserCard
          key={user.id}
          user={user.raw_user_meta_data.name}
          email={user.email}
          profile_pic={user.raw_user_meta_data.picture}
        />
      ))}
    </div>
  );
};
export default ViewUsers;
