import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import { SessionContext } from "./components/SessionContext";
import Cart from "./pages/cart/cart";
import Checkout from "./pages/checkout/checkout";
import EditMenu from "./pages/editmenu/editmenu";
import Landing from "./pages/landing/landing";
import OrderHistory from "./pages/orders/order";
import Portal from "./pages/portal/portal";
import Profile from "./pages/profile/profile";
import QrScannerPage from "./pages/scanner/QrScanner";
import ViewUsers from "./pages/viewusers/viewusers";
import { store } from "./redux/store";
import EggLoading from "./static/eggloading";
import supabase from "./supabase";
import Statistics from "./pages/statistics/Statistics";

function App() {
  const [session, setSession] = useState(null);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      // setLoading(false) // This is not needed as we are already checking for session in the next useEffect
    });
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
    return () => subscription.unsubscribe();
  }, []);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Replace this with your sign-in logic
    const checkSignInStatus = async () => {
      await new Promise((resolve) => setTimeout(resolve, 1200)); // Simulate a delay
      setLoading(false);
    };

    checkSignInStatus();
  }, []);

  if (loading) {
    return <EggLoading />;
  }

  return (
    <Provider store={store}>
      <SessionContext.Provider value={{ session, setSession }}>
        <Router>
          <Routes>
            <Route path="/" element={session ? <Portal /> : <Landing />} />
            <Route
              path="/scanner"
              element={session ? <QrScannerPage /> : <Landing />}
            />
            <Route
              path="/users"
              element={session ? <ViewUsers /> : <Landing />}
            />
            <Route
              path="/editmenu"
              element={session ? <EditMenu /> : <Landing />}
            />
            <Route
              path="/orders"
              element={session ? <OrderHistory /> : <Landing />}
            />

            <Route
              path="/statistics"
              element={session ? <Statistics /> : <Landing />}
            />
          </Routes>
        </Router>
      </SessionContext.Provider>
    </Provider>
  );
}

export default App;
