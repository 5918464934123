import { Dialog, Transition } from "@headlessui/react";
import { format } from "date-fns";
import { Fragment, useContext, useState } from "react";
import { BsQrCodeScan } from "react-icons/bs";
import {
  IoCheckmarkCircleSharp,
  IoFastFood,
  IoWarningOutline,
} from "react-icons/io5";
import NimiqScannerComponent from "../../components/NimiqScannerComponent";
import { SessionContext } from "../../components/SessionContext";
import EggLoading from "../../static/eggloading";
import InvalidQr from "../../static/invalid-qr.png";
import supabase from "../../supabase";
import "./qrscanner.css";
const Modal = ({ onServe, onDontServe }) => {
  return (
    <Transition.Root show={true} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={() => {}}
      >
        <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm" />
          </Transition.Child>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  <IoWarningOutline
                    size={25}
                    color="red"
                    className="inline-block mb-2"
                  />
                  Order has not been served yet!
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Do you want to serve or don't serve?
                  </p>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 flex justify-between items-center ">
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:w-auto sm:text-sm"
                  onClick={onServe}
                >
                  <IoFastFood size={20} />
                  Serve
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:w-auto sm:text-sm"
                  onClick={onDontServe}
                >
                  Don't Serve
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const OrderTable = ({ order, orderUser }) => {
  return (
    <>
      <div
        className="qrData-container productsans-regular"
        style={{ gap: "1em" }}
      >
        <h1 className="grifter-regular qr-data">Order Confirmed:</h1>
        <h1
          style={{
            marginTop: "-1em",
          }}
        >
          {format(new Date(order.created_at), "EEEE, do MMMM yyyy")}
        </h1>
        <h2
          className="productsans-regular qr-data"
          style={{
            fontSize: "1.5em",
            fontWeight: "bold",
          }}
        >
          {orderUser?.raw_user_meta_data?.name}
        </h2>
        <img
          src={orderUser?.raw_user_meta_data?.avatar_url}
          className="profile-image"
          alt="User Image"
          style={{
            borderRadius: "50%",
            width: "8em",
          }}
        />

        {order &&
          Object.values(order.items).map((orderedItem) => (
            <div
              className="dish-card"
              style={{
                width: "80%",
              }}
            >
              <div
                className="dish-left"
                style={{
                  paddingRight: "5em",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span>
                    <span
                      className="productsans-regular dish-name"
                      style={{
                        display: "inline-block",
                        verticalAlign: "middle",
                        fontSize: "1.5em",
                        letterSpacing: "1px",
                      }}
                    >
                      {orderedItem.name}
                    </span>
                  </span>
                  <span
                    className="dish-price"
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    x{orderedItem.count}
                  </span>
                </div>
              </div>
              <img className="dish-image" src={orderedItem.image} />
            </div>
          ))}
        {order && (
          <div
            className="productsans-regular"
            style={{
              fontSize: "1.5em",
            }}
          >
            <span className="total-amount">₹{order.amount}</span>
          </div>
        )}
      </div>
    </>
  );
};

const QrScannerPage = () => {
  const [order, setOrder] = useState(null);
  const [orderUser, setOrderUser] = useState(null);
  const [served, setServed] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [expired, setExpired] = useState(false);
  const [alreadyScanned, setAlreadyScanned] = useState(false);

  const [checkedOnce, setCheckedOnce] = useState(false);
  const handleServe = async () => {
    try {
      let { data, error } = await supabase
        .from("orders")
        .update({ served: true })
        .eq("order_id", order.order_id);
      setServed(true);
      setShowModal(false);
    } catch (error) {
      console.log("Error serving order:", error);
      alert("Something went wrong. Please report to the developers.");
    }
  };

  const handleScanAgain = () => {
    if (served === true) {
      setLoading(true);
      setOrder(null);
    } else if (expired) {
      setExpired(false);
      setLoading(true);
      setOrder(null);
    } else {
      setShowModal(true);
    }
  };

  const fetchOrder = async (orderId, userId) => {
    try {
      let { data, error } = await supabase
        .from("orders")
        .select("*")
        .eq("order_id", orderId)
        .eq("user_id", userId)
        .eq("served", false);

      console.log(data);
      if (data[0]) {
      
        fetchUser(userId);  
        setOrder(data[0]);


        setScanned(orderId);
      } else {
        setExpired(true);
      }

    } catch (error) {
      console.log("Error fetching order:", error);
      alert("Something went wrong. Please report to the developers.");
    }
  };

  const setScanned = async (orderId) => {
    try {
      let { data, error } = await supabase
        .from("orders")
        .update({ scanned: true })
        .eq("order_id", orderId);
    } catch (error) {
      console.log("Error serving order:", error);
      alert("Something went wrong. Please report to the developers.");
    }
  };

  const fetchUser = async (userId) => {
    try {
      let { data, error } = await supabase
        .from("users")
        .select("*")
        .eq("id", userId);

      console.log(data);
      setOrderUser(data[0]);
    } catch (error) {
      console.log("Error fetching user:", error);
      alert("Something went wrong. Please report to the developers.");
    }
  };




  const { session } = useContext(SessionContext);
  if (!session) {
    window.location.href = "/";
  }

  const [loading, setLoading] = useState(true);
  const handleScan = (text, result) => {
    setServed(false);
    try {
      const orderData = JSON.parse(text);
      fetchOrder(orderData.order_id, orderData.user_id)
        
      
    } catch (error) {
      alert("Something went wrong. Please report to the developers.");
    }

    setTimeout(() => {
      setLoading(false);
    }, 200);
  };



  return (
    <div className="qrscanner-page ">
      <div className="flex flex-col text-white text-center mt-10 poppins-regular gap-2">
        {order ? (
          ""
        ) : (
          <>
            <p className="text-3xl font-bold">QR Scanner</p>
            <p>Scan the QR code to view the order</p>
          </>
        )}
      </div>
      {loading ? (
        // <Scanner
        //   onResult={(text, result) => handleScan(text, result)}
        //   onError={(error) => alert(error?.message)}
        // />
        <NimiqScannerComponent
          onResult={(result) => {
            const text = result.data;
            handleScan(text);
          }}
          onError={(error) => {
            console.log(error);
          }}
          className=" mt-10 rounded-3xl px-2 square-video"
        />
      ) : (
        order && (
              <div>
                  {order.scanned === true && (

                    <div className="text-center productsans-regular">
                      <span
                        style={{
                          display: "block",
                          color: "#ef233c",
                          fontSize: "1.5em",
                          fontWeight: "bold",
                        }}
                      >
                        Error: Order has already been scanned!
                      </span>
                    </div>
                  )}
                  <OrderTable order={order} orderUser={orderUser} />

                
              </div>
        ) 
      )}
      {loading && <EggLoading />}

      {order && (
        
        <div
          className="scan-again poppins-regular"
          onClick={() => {
            handleServe();
          }}
          style={{
            color: served ? "#06d6a0" : "#ef233c",
            transition: served && "none",
          }}
        >
          {served ? (
            <IoCheckmarkCircleSharp size={25} />
          ) : (
            <>
            
              <IoCheckmarkCircleSharp size={35} />
              <span>Serve Order</span>
            </>
          )}
        </div>
      )}

      {expired && (
        <div className="text-center productsans-regular">
          <img src={InvalidQr} alt="QR Invalid" />
          <span
            style={{
              display: "block",
              color: "#ef233c",
              fontSize: "1.5em",
              fontWeight: "bold",
            }}
          >
            Error: Invalid or Expired Token!
          </span>
        </div>
      )}

    


      {!loading && (
        <div className="scan-again poppins-regular" onClick={handleScanAgain}>
          <BsQrCodeScan size={25} />
          <span>Scan Again</span>
        </div>
      )}
      {showModal && (
        <Modal
          onServe={handleServe}
          onDontServe={() => {
            setShowModal(false);
            setServed(true);
          }}
        />
      )}
    </div>
  );
};

export default QrScannerPage;
